import Loading from "components/MatxLoading";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "utils/utils";
// HOOK

export default function AuthGuard({ children }) {
  const {token, loading } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  if(loading){
    return <Loading/>;
  }


  if (token) return <>{children}</>;

  return <Navigate replace to="/login" state={{ from: pathname }} />;
}
