import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from 'config/apiConfig';
import { CAREER_PATHS, CAREER_TRACKS, TOOLS_TECH, EXAM_LIST } from '../../utils/constant';

export const fetchCareerTrackList = createAsyncThunk('dropdown/fetchCarrerTracks', async (_, {rejectWithValue}) => {
    try{
        const response = await API.get(`api/v1/load-dropdown/${CAREER_TRACKS}`);
        return response.data.data;
    }catch(e){
        return rejectWithValue('');
    }
});

export const fetchCareerPathList = createAsyncThunk('dropdown/fetchCarrerPaths', async (_, {rejectWithValue}) => {
    try{
        const response = await API.get(`api/v1/load-dropdown/${CAREER_PATHS}`);
        return response.data.data;
    }catch(e){
        return rejectWithValue('');
    }
});

export const fetchToolsTechnologyList = createAsyncThunk('dropdown/fetchToolsTechnologyList', async (_, {rejectWithValue}) => {
    try{
        const response = await API.get(`api/v1/load-dropdown/${TOOLS_TECH}`);
        return response.data.data;
    }catch(e){
        return rejectWithValue('');
    }
});

export const fetchExamList = createAsyncThunk('dropdown/fetchExamList', async (_, {rejectWithValue}) => {
    try{
        const response = await API.get(`api/v1/load-dropdown/${EXAM_LIST}`);
        return response.data.data;
    }catch(e){
        return rejectWithValue('');
    }
});