export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const TOKEN = "access_token";
export const IS_AUTH = "is_authenticated";
export const USER = "user";
export const USER_ID = "user_id";
export const USER_GUARD = "auth_guards";
export const PERMISSIONS = "user_permissions";
export const PROD = "prod";

// dropdown constants
export const CAREER_TRACKS = "careerTracks";
export const CAREER_PATHS = "careerPaths";
export const TOOLS_TECH = "toolsTechnologies";
export const EXAM_LIST = "exams";

export const ADMIN = 1;
export const INSRUCTOR = 2;
export const STUDENT = 3;

export const REQ_PENDING = 1;
export const REQ_PROCESSIN = 2;
export const REQ_READY_EXAM = 3;
export const REQ_EXAM_COMPLETED = 4;

export const contentTypeList =[
    {
      id: 1, label: 'Content',
    },
    {
      id: 2, label: 'Video'
    }
]

export const examTypeList =[
    {
      id: 1, label: 'Short Exam',
    },
    {
      id: 2, label: 'Final Exam'
    }
]

export const optionList =[
    {
      id: 'option_one', label: 'Option One',
    },
    {
      id: 'option_two', label: 'Option Two'
    },
    {
      id: 'option_three', label: 'Option Three'
    },
    {
      id: 'option_four', label: 'Option Four'
    }
]

export const gendarList =[
    {
      id: 'Male', label: 'Male',
    },
    {
      id: 'Female', label: 'Female'
    },
    {
      id: 'Other', label: 'Other'
    }
]

export const methodList =[
    {
      id: 'Bkash', label: 'Bkash',
    },
    {
      id: 'Nagad', label: 'Nagad'
    },
    {
      id: 'Bank', label: 'Bank'
    }
]

export const roleList =[
    {
      id: 1, label: 'Admin',
    },
    {
      id: 2, label: 'Instructor'
    },
    {
      id: 3, label: 'Student'
    }
]
export const examReqStatus = {
  1: 'Pending',
  2: 'Processing',
  3: 'Ready for Exam',
  4: 'Exam Completed'
}

export const paymentStatus = {
  1: 'Pending',
  2: 'Completed'
}

export const dashboardUrl =  {
  1 : '/',
  2 : '/instructor/dashboard',
  3 : '/student/dashboard'
}