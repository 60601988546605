import { lazy } from "react";
import Loadable from "components/Loadable.jsx";

const CareerTrack = Loadable(lazy(() => import("../views/general-setting/career-tracks/List.jsx")));
const CareerPath = Loadable(lazy(() => import("../views/general-setting/career-paths/List.jsx")));
const ToolsTechnologies = Loadable(lazy(() => import("../views/general-setting/tools-technology/List.jsx")));
const LearningContents = Loadable(lazy(() => import("../views/career/learning-contents/List.jsx")));
const CourseRegisters = Loadable(lazy(() => import("../views/career/exam-request/List.jsx")));
const RegisterPayments = Loadable(lazy(() => import("../views/career/register-payments/List.jsx")));
const Exams = Loadable(lazy(() => import("../views/general-setting/exams/List.jsx")));
const Questions = Loadable(lazy(() => import("../views/exam-manage/questions/List.jsx")));
const ExamAssesments = Loadable(lazy(() => import("../views/exam-manage/exam-assesments/List.jsx")));
const ExamCertificates = Loadable(lazy(() => import("../views/exam-manage/certificate/List.jsx")));

const Profile = Loadable(lazy(() => import("../views/profile/Form.jsx")));

//student Panel
const StudentExamRequest = Loadable(lazy(() => import("../views/student/exam-request/List.jsx")));
const StudentExam = Loadable(lazy(() => import("../views/student/exam/Form.jsx")));
const StudentCertificate = Loadable(lazy(() => import("../views/student/exam/Certificate.jsx")));
const StudentCertificateList = Loadable(lazy(() => import("../views/student/certificate/List.jsx")));

//student Panel
const Students = Loadable(lazy(() => import("../views/user-management/students/List.jsx")));
const Instructors = Loadable(lazy(() => import("../views/user-management/instructors/List.jsx")));
const Admins = Loadable(lazy(() => import("../views/user-management/admins/List.jsx")));


const materialRoutes = [
  { path: "/profile", element: <Profile /> },
  { path: "/career/learning-contents", element: <LearningContents /> },
  { path: "/career/exam-request", element: <CourseRegisters /> },
  { path: "/career/register-payments", element: <RegisterPayments /> },

  { path: "/exam-manage/questions", element: <Questions /> },
  { path: "/exam-manage/exam-assesments", element: <ExamAssesments /> },
  { path: "/exam-manage/certificates", element: <StudentCertificateList /> },

  { path: "/student/exam-requests", element: <StudentExamRequest /> },
  { path: "/student/exam", element: <StudentExam /> },
  { path: "/student/certificate/:id", element: <StudentCertificate /> },
  { path: "/student/certificates", element: <StudentCertificateList /> },

  { path: "/user-management/students", element: <Students /> },
  { path: "/user-management/instructors", element: <Instructors /> },
  { path: "/user-management/admins", element: <Admins /> },

  { path: "/general-setting/exams", element: <Exams /> },
  { path: "/general-setting/career-tracks", element: <CareerTrack /> },
  { path: "/general-setting/career-paths", element: <CareerPath /> },
  { path: "/general-setting/tools-technologies", element: <ToolsTechnologies /> },


 
];

export default materialRoutes;
