import { createSlice } from '@reduxjs/toolkit'
import { fetchUserData, login, signOut } from '../thunks/authThunk';

const initialState = {
    token: null,
    loading: false,
    user: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(signOut.fulfilled, (state, action) => {
        state.loading = false;
        state.user = {};
        state.token = null;
      })
      builder.addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(login.fulfilled, (state, action) => {
        console.log(action, 'ac');
        const {token, user} = action.payload;
        state.token = token;
        state.user = user;
        state.loading = false;
      })
      builder.addCase(login.rejected, (state, action) => {
        state.loading = false;
      })
      builder.addCase(fetchUserData.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(fetchUserData.fulfilled, (state, action) => {
        const {token, user} = action.payload;
        state.token = token;
        state.user = user;
        state.loading = false;        
      })
      builder.addCase(fetchUserData.rejected, (state, action) => {
          state.loading = false;
          state.user = {};
          state.token = null;        
      })
    },
})


export const {} = authSlice.actions;

export default authSlice.reducer;