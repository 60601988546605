import axios from "axios";
import { TOKEN } from "utils/constant";

const prod = true;

export const API_URL = prod ? "http://85.31.235.79:5000/" : "http://localhost:3000/";
export const BASE_URL = prod ? "http://85.31.235.79:5000/" : "http://localhost:3000/";


export const API = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});
export const FILE_API = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(TOKEN)}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  },
});

API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

FILE_API.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const PUBLIC_API = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers:{
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': true,
  }
});

export const PUBLIC_FORM_API = axios.create({
  baseURL: API_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const isLoggedIn = () => {
  if (localStorage.getItem(TOKEN) === null) {
    return false;
  }
  return true;
};