import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import App from "./App";

import store from "./redux/store.js"
import { Provider } from 'react-redux'

// third party style
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "utils/utils";
import { fetchUserData } from "./redux/thunks/authThunk";
const root = createRoot(document.getElementById("root"));

if (getToken()) {
  store.dispatch(fetchUserData());
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
serviceWorker.unregister();
