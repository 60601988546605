import { createAsyncThunk } from '@reduxjs/toolkit'
import {getToken, removeToken, setToken} from '../../utils/utils';
import { API, PUBLIC_API } from 'config/apiConfig';
import { useNavigate } from 'react-router-dom';

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (_, {rejectWithValue}) => {
    try{
        const accessToken = getToken();
        const response = await API.get('api/v1/user');
        return {user: response.data.data, token: accessToken};
    }catch(e){
        removeToken();
        return rejectWithValue('');
    }
});

export const login = createAsyncThunk('auth/login', async (payload) => {
  try{
    const {data:{message, data}} = await PUBLIC_API.post('api/v1/login', payload);
    setToken(data.token);
    return data;
  }catch(e){
    console.log(e.message);
  }
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
    removeToken();
});