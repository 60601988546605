import { createSlice } from '@reduxjs/toolkit'
import { fetchCareerPathList, fetchCareerTrackList, fetchExamList, fetchToolsTechnologyList } from '../thunks/commonDropdownThunk.js';

const initialState = {
    loading: false,
    careerTrackList: [],
    careerPathList: [],
    toolsTechnologyList: [],
    examList: [],
};

export const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(fetchCareerTrackList.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(fetchCareerTrackList.fulfilled, (state, action) => {
        console.log(action, 'ddz')
        state.careerTrackList = action.payload;
        state.loading = false;        
      })
      builder.addCase(fetchCareerTrackList.rejected, (state, action) => {
          state.loading = false;
          state.careerTrackList = [];
      })

      
      builder.addCase(fetchCareerPathList.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(fetchCareerPathList.fulfilled, (state, action) => {
        state.careerPathList = action.payload;
        state.loading = false;        
      })
      builder.addCase(fetchCareerPathList.rejected, (state, action) => {
          state.loading = false;
          state.careerPathList = [];
      })

      builder.addCase(fetchToolsTechnologyList.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(fetchToolsTechnologyList.fulfilled, (state, action) => {
        state.toolsTechnologyList = action.payload;
        state.loading = false;        
      })
      builder.addCase(fetchToolsTechnologyList.rejected, (state, action) => {
          state.loading = false;
          state.toolsTechnologyList = [];
      })

      builder.addCase(fetchExamList.pending, (state, action) => {
        state.loading = true;
      })
      builder.addCase(fetchExamList.fulfilled, (state, action) => {
        state.examList = action.payload;
        state.loading = false;        
      })
      builder.addCase(fetchExamList.rejected, (state, action) => {
          state.loading = false;
          state.examList = [];
      })

    },
})

export const {} = dropdownSlice.actions;
export default dropdownSlice.reducer;