import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "../auth/AuthGuard";
import { authRoles } from "../auth/authRoles";

import Loadable from "../components/Loadable";
import MatxLayout from "../components/MatxLayout/MatxLayout";

import materialRoutes from "routes/routes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("views/pages/NotFound")));
const Login = Loadable(lazy(() => import("views/pages/Login")));
const ForgotPassword = Loadable(lazy(() => import("views/pages/ForgotPassword")));

// DASHBOARD PAGE
const Dashboard = Loadable(lazy(() => import("views/dashboard/admin/Dashboard")));
const StudentDashboard = Loadable(lazy(() => import("views/student/dashboard/Dashboard")));
const InstructorDashboard = Loadable(lazy(() => import("views/instructor/dashboard/Dashboard")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      { path: "/dashboard", element: <Dashboard />, auth: authRoles.admin },
      { path: "/student/dashboard", element: <StudentDashboard />, auth: authRoles.admin },
      { path: "/instructor/dashboard", element: <InstructorDashboard />, auth: authRoles.admin },
    ]
  },

  // session pages route
  { path: "/404", element: <NotFound /> },
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
