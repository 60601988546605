import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import { MatxTheme } from "./components";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER

export default function App() {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
        <MatxTheme>
          <CssBaseline />
          <ToastContainer />
          {content}
        </MatxTheme>
    </SettingsProvider>
  );
}
