import { Card, Box, styled, Button, Grid } from "@mui/material";
import Add from '@mui/icons-material/AddCircleOutline';

// STYLED COMPONENTS
const CardRoot = styled(Card)({
  height: "100%",
  padding: "20px 24px"
});

const CardTitle = styled("div")(({ subtitle }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
  marginBottom: !subtitle && "16px"
}));

const AddButton = styled(Button) ({
  fontSize: "1rem",
});


export default function SimpleCard({ children, title, subtitle, handleModalOpen, addButton = true }) {
  return (
    <CardRoot elevation={6}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardTitle subtitle={subtitle}>{title}</CardTitle>
          {subtitle && <Box mb={2}>{subtitle}</Box>}
        </Grid>
        <Grid item align="end" xs={6}>
          { addButton && <AddButton  variant="contained" onClick={handleModalOpen} size="small" > <Add fontSize='small' />&nbsp;Add </AddButton>}
        </Grid>
      </Grid>
      {children}
    </CardRoot>
  );
}
