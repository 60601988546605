import { configureStore } from '@reduxjs/toolkit'
import userSlice from "./slices/userSlice";
import careerTrackSlice from './slices/careerTrackSlice';
import careerPathSlice from './slices/careerPathSlice';
import authReducer from './slices/authSlice'
import dropdownSlice from './slices/dropdownSlice';
import toolsTechSlice from './slices/toolsTechSlice';
import learningContentSlice from './slices/learningContentSlice';
import examSlice from './slices/examSlice';
import questionsSlice from './slices/questionsSlice';
import studentSlice from './slices/studentSlice';
import adminSlice from './slices/adminSlice';
import instructorSlice from './slices/instructorSlice';
import examRequestSlice from './slices/examRequestSlice';
import examPaymentSlice from './slices/examPaymentSlice';
import examAssesmentSlice from './slices/examAssesmentSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    dropdown: dropdownSlice,
    users: userSlice,
    careerTracks: careerTrackSlice,
    careerPaths: careerPathSlice,
    toolsTechnologies: toolsTechSlice,
    learningContents: learningContentSlice,
    exams: examSlice,
    questions: questionsSlice,
    students: studentSlice,
    admins: adminSlice,
    instructors: instructorSlice,
    examRequests: examRequestSlice,
    examPayments: examPaymentSlice,
    examAssesments: examAssesmentSlice,
  }
});