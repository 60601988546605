
import { INSRUCTOR } from 'utils/constant';

const PROFILE_UPDATE = "api/v1/user/update-profile";


const DASHBOARD = "api/v1/dashboard";
const STUDENT_DASHBOARD = "api/v1/student/dashboard";
const INSRUCTOR_DASHBOARD = "api/v1/instructor/dashboard";



const IMAGE_VIEW = "api/v1/image/view";




const CAREER_TRACK_LIST = "api/v1/career-tracks";
const CAREER_TRACK_STORE = "api/v1/career-tracks/store";
const CAREER_TRACK_UPDATE = "api/v1/career-tracks/update";
const CAREER_TRACK_DELETE = "api/v1/career-tracks/delete";


const CAREER_PATH_LIST = "api/v1/career-paths";
const CAREER_PATH_STORE = "api/v1/career-paths/store";
const CAREER_PATH_UPDATE = "api/v1/career-paths/update";
const CAREER_PATH_DELETE = "api/v1/career-paths/delete";

const TOOLS_TECH_LIST = "api/v1/tools-technologies";
const TOOLS_TECH_STORE = "api/v1/tools-technologies/store";
const TOOLS_TECH_UPDATE = "api/v1/tools-technologies/update";
const TOOLS_TECH_DELETE = "api/v1/tools-technologies/delete";

const LEARNING_CONTENT_LIST = "api/v1/learning-contents";
const LEARNING_CONTENT_STORE = "api/v1/learning-contents/store";
const LEARNING_CONTENT_UPDATE = "api/v1/learning-contents/update";
const LEARNING_CONTENT_DELETE = "api/v1/learning-contents/delete";

const EXAM_LIST = "api/v1/exams";
const EXAM_STORE = "api/v1/exams/store";
const EXAM_UPDATE = "api/v1/exams/update";
const EXAM_DELETE = "api/v1/exams/delete";
const SINGLE_EXAM = "api/v1/exams/";


const EXAM_REQ_LIST = "api/v1/exam-requests";
const EXAM_REQ_STORE = "api/v1/exam-requests/store";
const EXAM_REQ_UPDATE = "api/v1/exam-requests/update";
const EXAM_REQ_DELETE = "api/v1/exam-requests/delete";

const EXAM_ASSESMENT_LIST = "api/v1/exam-results";


const QUESTIONS_LIST = "api/v1/questions";
const QUESTIONS_STORE = "api/v1/questions/store";
const QUESTIONS_UPDATE = "api/v1/questions/update";
const QUESTIONS_DELETE = "api/v1/questions/delete";

const EXAM_PAYMENT = "api/v1/exam-payments";
const EXAM_PAYMENT_STORE = "api/v1/exam-payments/store";
const EXAM_PAYMENT_UPDATE = "api/v1/exam-payments/update";
const EXAM_PAYMENT_DELETE = "api/v1/exam-payments/delete";
const PAYMENT_STATUS_CHANGE = "api/v1/exam-payments/status";

const EXAM_RESULT_LIST = "api/v1/exam-results";
const EXAM_RESULT_STORE = "api/v1/exam-results/store";
const EXAM_RESULT_UPDATE = "api/v1/exam-results/update";
const EXAM_RESULT_DELETE = "api/v1/exam-results/delete";
const CERTIFICATE_VIEW = "api/v1/exam-results/view";

const STUDENT_LIST = "api/v1/students";
const STUDENT_STORE = "api/v1/students/store";
const STUDENT_UPDATE = "api/v1/students/update";
const STUDENT_DELETE = "api/v1/students/delete";

const INSRUCTOR_LIST = "api/v1/students";
const INSRUCTOR_STORE = "api/v1/students/store";
const INSRUCTOR_UPDATE = "api/v1/students/update";
const INSRUCTOR_DELETE = "api/v1/students/delete";

const ADMIN_LIST = "api/v1/students";
const ADMIN_STORE = "api/v1/students/store";
const ADMIN_UPDATE = "api/v1/students/update";
const ADMIN_DELETE = "api/v1/students/delete";


export {

  DASHBOARD,
  STUDENT_DASHBOARD,
  INSRUCTOR_DASHBOARD,
  IMAGE_VIEW,
  
  PROFILE_UPDATE,
  
  CAREER_TRACK_LIST,
  CAREER_TRACK_STORE,
  CAREER_TRACK_UPDATE,
  CAREER_TRACK_DELETE,

  CAREER_PATH_LIST,
  CAREER_PATH_STORE,
  CAREER_PATH_UPDATE,
  CAREER_PATH_DELETE,

  TOOLS_TECH_LIST,
  TOOLS_TECH_STORE,
  TOOLS_TECH_UPDATE,
  TOOLS_TECH_DELETE,

  LEARNING_CONTENT_LIST,
  LEARNING_CONTENT_STORE,
  LEARNING_CONTENT_UPDATE,
  LEARNING_CONTENT_DELETE,

  EXAM_LIST,
  EXAM_STORE,
  EXAM_UPDATE,
  EXAM_DELETE,
  SINGLE_EXAM,
  
  EXAM_REQ_LIST,
  EXAM_REQ_STORE,
  EXAM_REQ_UPDATE,
  EXAM_REQ_DELETE,
  CERTIFICATE_VIEW,

  QUESTIONS_LIST,
  QUESTIONS_STORE,
  QUESTIONS_UPDATE,
  QUESTIONS_DELETE,

  EXAM_RESULT_LIST,
  EXAM_RESULT_STORE,
  EXAM_RESULT_UPDATE,
  EXAM_RESULT_DELETE,

  EXAM_PAYMENT,
  EXAM_PAYMENT_STORE,
  EXAM_PAYMENT_UPDATE,
  EXAM_PAYMENT_DELETE,
  PAYMENT_STATUS_CHANGE,
  
  STUDENT_LIST,
  STUDENT_STORE,
  STUDENT_UPDATE,
  STUDENT_DELETE,

  INSRUCTOR_LIST,
  INSRUCTOR_STORE,
  INSRUCTOR_UPDATE,
  INSRUCTOR_DELETE,

  ADMIN_LIST,
  ADMIN_STORE,
  ADMIN_UPDATE,
  ADMIN_DELETE,

  EXAM_ASSESMENT_LIST
}